.hui-i{
	display: inline-block;
}

@mixin icons($sprites) {
	@each $sprite in $sprites {
		$sprite-name: nth($sprite, 10);
		.hui-svg-#{$sprite-name} {
			@include sprite($sprite);
			display: inline-block;
		}
	}
}


