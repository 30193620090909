@mixin button-props($size: "name") {
	&.#{map-get($size, "name")} {
		height: map-get($size, "height");
		line-height: map-get($size, "line-height");
		padding-left: map-get($size, "padding");
		padding-right: map-get($size, "padding");
		font-size: map-get($size, "font-size");
		border-radius: map-get($size, "radius");
	}
}


@mixin button-color($name: "name", $color: $hui-color-black, $contrast: 10%, $text: $hui-color-white) {
	&.hui-btn-#{$name} {
		background-color: $color;
		color: $text;

		.btn-icon {
			stroke: #ffffff;
		}

		&:hover, &:active {
			background-color: lighten($color, $contrast);
			color: $text;
			border-color: rgba($hui-color-black, .15);
		}

		&.hui-btn-outline {
			color: $color;

			&:hover, &:active {
				background-color: $hui-color-white;
				color: $color;
				border-color: $color;
			}
		}
	}
}



@mixin button-color-light($name: "name", $color: $hui-color-black, $contrast: 10%, $text: $hui-color-white) {
  &.hui-btn-#{$name} {
    background-color: $color;
    color: $text;
    border: 0;
    line-height: 32px;
    height: 32px;
    padding: 0 9px;
    font-size: 12px;
    display: inline-block;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.03);
    text-transform: uppercase;

    @include button-color($name: "black", $color: $color, $contrast: $contrast, $text: $text);
 }
}



@mixin button-circle-size($class: "default") {
	&.#{map-get($class, "name")} {
		width: if(map-get($class, "name") == 'hui-btn', map-get($class, "width"), map-get($class, "width") !important);
		max-width: if(map-get($class, "name") == 'hui-btn', map-get($class, "max-width"), map-get($class, "max-width") !important);
	}
}

@mixin button-circle-sizes($sizes) {
	@each $size in $sizes {
		@include button-circle-size($class: $size);
	}
}


@mixin button-icon-size($class: "default") {
	&.#{map-get($class, "name")} {
		.hui-i {
			width: if(map-get($class, "name") == 'hui-btn', map-get($class, "icon"), map-get($class, "icon"));
			height: if(map-get($class, "name") == 'hui-btn', map-get($class, "icon"), map-get($class, "icon"));
		}
	}
}

@mixin button-icon-sizes($sizes) {
	@each $size in $sizes {
		@include button-icon-size($class: $size);
	}
}

