@mixin hover($colors: "transparent") {
  @each $color, $value in $colors {
    &.hui-bg-#{$color} {
      &:hover{
        background-color: rgba(scale-color($value, $lightness: -50%), 0.12) !important;
      }
    }

    &.hui-border-#{$color} {
      &:hover{
        border-color: rgba(scale-color($value, $lightness: -50%), 0.12) !important;
      }
    }
  }
}
