.hui-dashboard {
  .hui-panel-content {
    .rgt-container {
      max-height: 320px;
    }
  }
}

.hui-dashboard-panel {
  width: 430px;
  min-width: 430px;
  padding: 30px;
}

.hui-dashboard-payment-link{
  cursor: pointer;
  text-decoration: none;
  color: $hui-color-black;
}

.hui-dashboard-payment-item {
  cursor: pointer;

  .hui-text{
    text-decoration: none;
  }

  &:hover {
    background-color: $hui-color-border-light;
    .hui-dashboard-payment-amount{
      text-decoration: underline !important;
    }
    .hui-text{
      color: $hui-color-black !important;
    }
  }
}

.hui-dashboard-company {
  .hui-dashboard-company-map {
    height: 175px;
    width: 100%;
    overflow: hidden;
  }
}


.hui-dashboard-balance-info {
  min-width: 80px;
  width: 80px;
}


.hui-dashboard-summary {
  text-decoration: none;
  cursor: default;

  &.cursor-pointer {
    cursor: pointer !important;

    &:hover {
      background-color: $hui-color-border-light !important;

      .hui-subtitle {
        text-decoration: underline !important;
      }
      .hui-text-xs {
        color: $hui-color-black !important;
      }
    }
  }
}

.hui-dashboard-summary-disabled {
  cursor: default;
  pointer-events: none;
  filter: saturate(.6);

  &.hui-subtitle {
    opacity: 0.6;
  }
}

