.hui-hover {
  @include hover($hui-colors);

  &.border{
    &:hover {
      border-color: $hui-color-default !important;
    }
  }

  &-zoom {
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }
}
