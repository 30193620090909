// DO NOT CHANGE THIS FILE.
//
// WARNING: THIS FILE IS DELETED AND RE-RECREATED EACH TIME THE SPRITES IS
// GENERATED. DO NOT PUT YOUR OWN CODE IN THIS FILE.
//
// TO UPDATE IT:
//
// --------------------------------------
//
// 1. ADD YOUR ICONS TO THE FOLDER:
// src/assets/sprite-svg/
//
// --------------------------------------
//
// 2. RUN THIS COMMAND LINE TO GENERATE THE SPRITES
// run npm sprites
//
// --------------------------------------

%svg-common {
  background: url("../../../../assets/sprite-svg-1c0e6207.svg") no-repeat;
}

.hui-svg-CAD {
  @extend %svg-common;
  background-position: 0 0;
  width: 32px !important;
  height: 32px !important;
}

.hui-svg-USD {
  @extend %svg-common;
  background-position: 0 5.498281786941581%;
  width: 32px !important;
  height: 32px !important;
}

.hui-svg-arrow-sm-right {
  @extend %svg-common;
  background-position: 0 10.64891846921797%;
  width: 14px !important;
  height: 13px !important;
}

.hui-svg-check-blue-sm {
  @extend %svg-common;
  background-position: 0 12.962962962962964%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-check-sm {
  @extend %svg-common;
  background-position: 0 16.239745521513477%;
  width: 13.1px !important;
  height: 16.7px !important;
}

.hui-svg-credit {
  @extend %svg-common;
  background-position: 0 19.085886489201407%;
  width: 16.7px !important;
  height: 16.7px !important;
}

.hui-svg-dropdown {
  @extend %svg-common;
  background-position: 0 21.65289256198347%;
  width: 13px !important;
  height: 9px !important;
}

.hui-svg-error-sm {
  @extend %svg-common;
  background-position: 0 23.569023569023567%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-google {
  @extend %svg-common;
  background-position: 0 27.187765505522513%;
  width: 25px !important;
  height: 25.5px !important;
}

.hui-svg-handle-icon {
  @extend %svg-common;
  background-position: 0 31.95876288659794%;
  width: 32px !important;
  height: 32px !important;
}

.hui-svg-help-sm {
  @extend %svg-common;
  background-position: 0 36.7003367003367%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-icon-i {
  @extend %svg-common;
  background-position: 0 39.93288590604027%;
  width: 18px !important;
  height: 18px !important;
}

.hui-svg-icon-phone {
  @extend %svg-common;
  background-position: 0 43.0976430976431%;
  width: 19px !important;
  height: 20px !important;
}

.hui-svg-info-gray-sm {
  @extend %svg-common;
  background-position: 0 46.464646464646464%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-info-sm {
  @extend %svg-common;
  background-position: 0 49.831649831649834%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-invoice-sm-icon {
  @extend %svg-common;
  background-position: 0 53.37837837837838%;
  width: 22px !important;
  height: 22px !important;
}

.hui-svg-logo-amex {
  @extend %svg-common;
  background-position: 0 57.561307901907355%;
  width: 26.8px !important;
  height: 26.8px !important;
}

.hui-svg-logo-discovery {
  @extend %svg-common;
  background-position: 0 60.320608163939845%;
  width: 43px !important;
  height: 8.9px !important;
}

.hui-svg-logo-mastercard {
  @extend %svg-common;
  background-position: 0 63.36834971196204%;
  width: 30px !important;
  height: 23.8px !important;
}

.hui-svg-logo-visa {
  @extend %svg-common;
  background-position: 0 66.21194476792547%;
  width: 35.8px !important;
  height: 12.9px !important;
}

.hui-svg-success-sm {
  @extend %svg-common;
  background-position: 0 69.1919191919192%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-success-sm-white {
  @extend %svg-common;
  background-position: 0 72.55892255892256%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-toastr-close {
  @extend %svg-common;
  background-position: 0 76.31133671742809%;
  width: 23px !important;
  height: 23px !important;
}

.hui-svg-toastr-error {
  @extend %svg-common;
  background-position: 0 80.47538200339558%;
  width: 25px !important;
  height: 25px !important;
}

.hui-svg-toastr-info {
  @extend %svg-common;
  background-position: 0 84.71986417657045%;
  width: 25px !important;
  height: 25px !important;
}

.hui-svg-toastr-success {
  @extend %svg-common;
  background-position: 0 88.96434634974533%;
  width: 25px !important;
  height: 25px !important;
}

.hui-svg-toastr-warning {
  @extend %svg-common;
  background-position: 0 93.2088285229202%;
  width: 25px !important;
  height: 25px !important;
}

.hui-svg-warning-sm {
  @extend %svg-common;
  background-position: 0 96.63299663299664%;
  width: 20px !important;
  height: 20px !important;
}

.hui-svg-x {
  @extend %svg-common;
  background-position: 0 100%;
  width: 20px !important;
  height: 20px !important;
}
