.hui-payment-logo{
  width: 180px;
}

.hui-payment-panel {
  min-height: 300px;

  &:before {
    content: '';
    position: absolute;
    width: 150px;
    height: 84px;
    border-radius: 100%;
    background: $hui-color-green;
    filter: blur(100px);
    left: 200px;
    top: -118px;
  }
}

.hui-payment-panel-theme-color{
  width: 150px;
  height: 84px;
  border-radius: 100%;
  display: block;
}

.hui-payment-panel-theme {
  position: absolute;
  filter: blur(100px);
  left: -163px;
  top: -118px;
}


.hui-payment-panel-logo {
  filter: blur(80px);
  position: absolute;
  right: -100px;
  top: -170px;

  img {
    width: auto !important;
    height: 100px !important;
    object-fit: contain;
  }
}


.hui-payment-method {
  background-color: #EEEEEE !important;
  box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 17px !important;
}
