.hui-btn {
  &.hui-btn[disabled="disabled"],
  &.hui-btn[disabled],
  &.hui-btn-disabled,
  &[disabled] {
    opacity: .4;
    background-image: none;
    cursor: default;
    pointer-events: none;


    &.hui-btn-outline {
      color: $hui-color-default;
    }

    &.hui-btn-disabled-gray {
      opacity: 1;
      background-color: $hui-color-gray-light;
      color: $hui-color-default;
      border-color: $hui-color-border;

      &:hover, &:active {
        background-color: $hui-color-gray-light;
        border-color: $hui-color-border;
        color: $hui-color-default;
      }
    }
  }
}

.hui-select-payment-minimum-alert {
  .hui-btn {
    &[disabled] {
      color: whitesmoke !important;
    }
  }
}

