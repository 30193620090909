.hui-badge {
  border: 0;
  line-height: 32px;
  height: 32px;
  padding: 0 9px;
  font-size: 12px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .05);
  text-transform: uppercase;
  position: relative;

  svg {
    vertical-align: sub;
  }

  &-sm {
    padding: 0 7px;
    line-height: 20px;
    height: 20px;
    font-size: 11px;
    min-width: 40px;
  }

  &.hui-badge-icon {
    line-height: 40px;
    height: 40px;
  }
}

@include badges($hui-colors);




