#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

.hui-toast {
  top: 20px;
  right: 0;
  width: 100%;

  .toast {
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 15px 50px;
    border-radius: 10px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    min-height: 60px;

    @include toastr-types('error', $hui-color-red);
    @include toastr-types('success', $hui-color-green);
    @include toastr-types('info', $hui-color-blue);
    @include toastr-types('warning', $hui-color-yellow);

    &:before{
      content: '';
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
    }

    .toast-message {
      word-wrap: break-word;
    }

    .toast-close-button {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      border: 0;
      cursor: pointer;
      background: transparent;
      appearance: none;
      width: 23px;
      height: 23px;
      border-radius: 100%;
      display: block;
    }

    @media all and (max-width: 768px) {
      padding: 10px 50px;

      .toast-close-button{
        right: 10px;
      }
    }
  }
}


