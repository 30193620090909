.hui-nav-portal {
  user-select: none;

  &.has-portal {
    cursor: pointer;

    &:hover {
      background-color: $hui-color-gray-light;

      .hui-nav-portal-dropdown {
        opacity: 1;
      }
    }
  }
}

.hui-nav-portal-dropdown {
  opacity: .4;
}

.hui-nav-portal-menu {
  width: 260px;
}

.hui-nav-portal-logo {
  width: 60px;
}
