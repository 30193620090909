$hui-color-transparent: rgba(255, 255, 255, 0);
$hui-color-white: #ffffff;
$hui-color-black: #202020;
$hui-color-black-1: #515350;
$hui-color-black-light: #606060;

$hui-color-gray: #9A9A9A;
$hui-color-gray-light: #F7F7F8;

$hui-color-default: #8E909E;
$hui-color-default-light: #f3f3f5;
$hui-color-border: #E1E1E1;
$hui-color-border-light: #EEEEEE;

$hui-color-purple-dark: #371c5d;
$hui-color-purple: #563683;
$hui-color-purple-light: #f3eefb;

$hui-color-blue-dark: #02517d;
$hui-color-blue: #007DC3;
$hui-color-blue-light: #EDF6FB;

$hui-color-navy-dark: #1f2b4a;
$hui-color-navy: #465478;
$hui-color-navy-light: #e7ebed;

$hui-color-teal-dark: #064747;
$hui-color-teal: #007c7c;
$hui-color-teal-light: #e6f0f0;

$hui-color-green-dark: #127632;
$hui-color-green: #1BA548;
$hui-color-green-light-border: #e5efe8;
$hui-color-green-light: #EDF8F0;

$hui-color-red-dark: #970613;
$hui-color-red: #E41B13;
$hui-color-red-light: #FEF4F3;

$hui-color-yellow-dark: #f09618;
$hui-color-yellow: #FF9F1C;
$hui-color-yellow-light: #FFF7EF;

$hui-color-orange-dark: #bb5512;
$hui-color-orange: #f47826;
$hui-color-orange-light: #fff5ef;

$hui-color-burgundy-dark: #340a1b;
$hui-color-burgundy: #510d22;
$hui-color-burgundy-light: #fbf0f1;

$hui-colors: (
  "transparent": $hui-color-transparent,
  "white": $hui-color-white,
  "black": $hui-color-black,
  "black-1": $hui-color-black-1,
  "black-light": $hui-color-black-light,
  "gray": $hui-color-gray,
  "gray-light": $hui-color-gray-light,
  "default": $hui-color-default,
  "default-light": $hui-color-default-light,
  "border": $hui-color-border,
  "border-light": $hui-color-border,
  "purple-dark": $hui-color-purple-dark,
  "purple": $hui-color-purple,
  "purple-light": $hui-color-purple-light,
  "teal-dark": $hui-color-teal-dark,
  "teal": $hui-color-teal,
  "teal-light": $hui-color-teal-light,
  "blue-dark": $hui-color-blue-dark,
  "blue": $hui-color-blue,
  "blue-light": $hui-color-blue-light,
  "navy-dark": $hui-color-navy-dark,
  "navy": $hui-color-navy,
  "navy-light": $hui-color-navy-light,
  "green-dark": $hui-color-green-dark,
  "green": $hui-color-green,
  "green-light": $hui-color-green-light,
  "red-dark": $hui-color-red-dark,
  "red": $hui-color-red,
  "red-light": $hui-color-red-light,
  "yellow-dark": $hui-color-yellow-dark,
  "yellow": $hui-color-yellow,
  "yellow-light": $hui-color-yellow-light,
  "orange-dark": $hui-color-orange-dark,
  "orange": $hui-color-orange,
  "orange-light": $hui-color-orange-light,
  "burgundy-dark": $hui-color-burgundy-dark,
  "burgundy": $hui-color-burgundy,
  "burgundy-light": $hui-color-burgundy-light,
);

$hui-borders: (
  "light": $hui-color-border-light,
  "green-light": $hui-color-green-light-border,
);

// themes colors

$hui-color-theme-green-dark: #1f4d3a;
$hui-color-theme-green: #24835A;
$hui-color-theme-green-light: #F2F7F5;
$hui-color-theme-green-border: #DAE7E3;

$hui-color-theme-blue-dark: #0f4cb4;
$hui-color-theme-blue: #367ef9;
$hui-color-theme-blue-light: #f0f5fe;
$hui-color-theme-blue-border: #d1ddf1;

$hui-color-theme-light-blue: #47b8fd;
$hui-color-theme-light-light: #f0f5fe;
$hui-color-theme-light-border: #d1ddf1;

$hui-color-theme-united-rentals: #095597;
$hui-color-theme-united-rentals-light: #f4f5f8;
$hui-color-theme-united-rentals-border: #d1d8e4;


:root {
  --bs-border-color: #E1E1E1 !important;
}
