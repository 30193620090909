.hui-sidebar-panel {
  width: $hui-sidebar-panel-width;
  min-width: $hui-sidebar-panel-width;
  max-width: $hui-sidebar-panel-width;
  left: 0;
  bottom: 0;
  z-index: 99;
  top: 0;
  height: calc(100vh);

  &.hui-sidebar-panel-lg {
    width: $hui-sidebar-panel-width-lg;
    min-width: $hui-sidebar-panel-width-lg;
  }

  @media (max-width: 991px) {

    width: 100%;
    min-width: 100%;
    max-width: 100%;

    + div{
      display: none !important;
    }
  }
}

.hui-content-sidebar{
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: $hui-sidebar-panel-width;
  position: relative;

  &.hui-content-sidebar-lg {
    padding-right:  $hui-sidebar-panel-width-lg;
  }
}

.hui-sidebar-fixed{
  right: 0;
  bottom: 0;
  z-index: 99;
  left: inherit !important;
  top: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $hui-color-gray-light;
  border-left: 1px solid $border-color;
  box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.10);

  .hui-header{
    position: sticky;
  }
}
