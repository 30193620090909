.hui-card {
  background: #FFFFFF;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $hui-card-padding - 10;
  overflow: hidden;
  
  .hui-card-header {
    padding-top: 10px;
    padding-bottom: $hui-card-padding - 10;
    padding-left: $hui-card-padding;
    padding-right: $hui-card-padding;
  }

  .hui-card-icon {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .hui-card-content {
    padding-bottom: $hui-card-padding - 10;
    padding-left: $hui-card-padding;
    padding-right: $hui-card-padding;
    flex-grow: 1;
  }

  .hui-card-actions {
    border: 0;
    border-style: solid;
    border-top-width: 1px;
    border-color: $hui-color-border;
    padding: 20px $hui-card-padding;
  }

  &.hui-card-shadow {
    box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
  }
}
