.hui-btn {
	&.hui-btn-circle {
		@include button-circle-sizes($hui-btn-sizes);
		position: relative;
		min-width: initial;
		padding: 0;
		overflow: hidden;
		font-size: 0;
		display: inline-block;
		border-radius: $hui-radius-rounded;

		.hui-i {
			margin-right: 0 !important;
			position: absolute !important;
			transform: translate(-50%, -50%) !important;
			left: 50%;
		}
	}
}
