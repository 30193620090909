.hui-nav-menu {
  padding: 20px;
}

.hui-nav-menu-item {
  height: 58px;
  line-height: 58px;
  display: flex;
  font-weight: bold;

  &:not(.active) {
    box-shadow: 0 1px 0 0 $hui-color-border-light;

    &:first-child {
      box-shadow: 0 1px 0 0 $hui-color-border-light;
    }

    &:last-child {
      box-shadow: none;
    }
  }

  &:hover {
    border-radius: 15px;
    background: $hui-color-border-light;
    box-shadow: 0 0 0 1px white, inset 0 0 0 1px white !important;
    z-index: 1;
    position: relative;

    .hui-nav-menu-link {
      color: $hui-color-black!important;
    }

  }

  &.active {
    border-radius: 15px;
    background: #F2F7F5;
    box-shadow: 0 0 0 5px white;
  }
}


.hui-nav-menu-link {
  display: flex;
  width: 100%;
  color: $hui-color-default !important;
  text-decoration: none;
}


.hui-nav-menu-icon {
  padding: 0 10px 0 7px;
}
