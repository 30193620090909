.hui-header {
  height: $hui-header;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  padding: $hui-header-padding;
}

.hui-header-toggle{
  @media (min-width: 768px) {
    display: none !important;
  }
}

.hui-header-padding {
  top: $hui-header;

  .hui-nav, .hui-sidebar-panel {
    height: calc(100vh - #{$hui-header});
    top: $hui-header;
  }
}


.hui-header-logo {
  width: 235px;
  background-color: white;

  img {
    width: auto;
    max-height: 45px;
    height: auto;
    max-width: 220px;
  }

  a {
    text-decoration: none;
    font-size: 18px;
    color: $hui-color-black;
    font-weight: bold;
  }

  &.header-logo-lg{
    width: 335px;
    img {
      max-height: 90px;
      max-width: 320px;
    }
  }
}


.hui-header-profile {
  border-left: 1px solid $hui-color-border;

  @media (min-width: 768px) {
    width: 275px;
  }

  &:hover {
    .hui-header-user {
      color: $hui-color-black;
    }

    .hui-header-dropdown {
      opacity: 1;
    }
  }
}

.hui-header-avatar {
  color: $hui-color-black !important;
  border-width: 1px;
  border-style: solid;
  background-color: $hui-color-gray-light !important;
  border-color: $hui-color-border !important;
}

.hui-header-user {
  color: $hui-color-gray;
}

.hui-header-dropdown {
  opacity: .4;
}

.hui-header-menu {
  width: 170px;
}
