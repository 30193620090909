// Import all commons
@import 'hui/hui-commons/hui-commons';

// Import Bootstrap
@import 'vendors/bootstrap';
@import 'vendors/bootstrap-modify';

// Import all styles
@import 'hui/hui-styles/hui-styles';

// Import all containers
@import 'hui/hui-containers/hui-containers';

// Import layouts
@import 'hui/hui-layouts/hui-layout';

// Import all core
@import 'hui/hui-core/hui-core';

// Import all content
@import 'hui/hui-content/hui-content';

// Import all utils
@import 'hui/hui-utils/hui-utils';

// Import themes
@import 'hui/hui-themes/hui-themes';

// Import components
@import 'hui/hui-components/hui-components';

.hui-modal-draggable .hui-modal-move {
  cursor: grab;
  height: 25px;
  width: 7px;
  background-color: transparent;
  background-image: radial-gradient(#ccc 1px, transparent 0);
  background-size: 4px 4px;
  display: inline-block;
  vertical-align: sub;
}

.hui-modal {
  display: block;
  overflow: hidden;
  outline: none;
}

.hui-modal-content {
  background: white;
  border-radius: 15px;
  width: 100%;
  min-width: 100%;
  border: 4px solid rgba(0, 0, 0, 0.3);
  display: block;
  overflow: hidden;
}

.hui-modal-full {
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 100%;
  border-radius: 0;
  border: none;
}

.hui-modal-lg {
  max-width: 900px;
  width: 900px;
}

.hui-modal-md {
  max-width: 600px;
  width: 600px;
}

.hui-modal-sm {
  max-width: 400px;
  width: 400px;
}

.hui-modal-header {
  padding: 15px;
}

.hui-modal-draggable {
  z-index: 99999999999;
  position: absolute;
}

.hui-modal-body {
  padding: 0 15px 15px;
}

.hui-modal-actions {
  padding: 0 15px 15px;
}

.hui-no-padding {
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media print {
  .buttons-print {
    display: none;
  }

  .hui-payment-panel {
    min-height: inherit;
  }
}
