.hui-btn {
	&.hui-btn-icon {
		@include button-icon-sizes($hui-btn-sizes);
		position: relative;

    .hui-i{
      margin: 0 !important;
    }
	}

	.hui-i {
    display: inline-block;
    vertical-align: top;
		margin-right: 10px;
		position: relative;
		transform: translateY(-50%);
		top: 50%;
	}

  &.hui-btn-icon-right{
    .hui-i {
      margin-right: 0 !important;
      margin-left: 10px !important;
    }
  }
}
