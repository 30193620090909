.hui-portal-logo {
  width: 235px;
  background-color: white;

  img {
    width: auto;
    max-width: 200px;
    max-height: 65px;
  }
  
  .hui-portal-link{
    display: inline-block;
  }
}
