.hui-nav-footer {
  line-height: 50px;
}


.hui-nav-footer-text {
  line-height: 14px;
}

.hui-nav-footer-icon {
  background-color: #fff;
  overflow: hidden;
  box-shadow: 3px 5px 20px rgba(142, 144, 158, 0.1);
  border-radius: 100%;
  width: 32px;
  height: 32px;
}
