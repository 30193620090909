
.hui-toggle {
  .hui-toggle-title {
    background-color: $hui-color-gray-light;
    border-width: 1px;
    border-style: solid;
    border-color: $hui-color-gray-light;

    &:hover {
      border-color: $hui-color-default;

      .hui-toggle-dropdown {
        opacity: 1;
      }
    }
  }

  .hui-toggle-dropdown {
    opacity: .4;
  }

  &.is-open {
    .hui-toggle-dropdown {
      .hui-i {
        transform: rotate(180deg);
      }
    }
  }
}
