@use "sass:color";

@mixin badges($colors: "transparent") {
  @each $color, $value in $colors {
    .hui-badge-bg-#{$color} {
      background-color: $value;

      &.hui-badge-arrow {
        &:before, &:after {
          position: absolute;
          content: "";
          user-select: none;
        }

        &:before {
          width: 8px;
          height: 8px;
          background-color: $value;
          box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .05);
        }

        &:after {
          background-color: $value;
        }
      }

      &.hui-badge-arrow-left, &.hui-badge-arrow-right {
        &:before {
          transform: translateY(-50%) rotate(45deg);
          top: 50%;
        }

        &:after {
          transform: translateY(-50%);
          top: 50%;
          width: 7px;
          height: 16px;
        }
      }

      &.hui-badge-arrow-left {
        &:before {
          left: -3px;
        }

        &:after {
          left: 1px;
        }
      }

      &.hui-badge-arrow-right {
        &:before {
          right: -3px;
        }

        &:after {
          right: 1px;
        }
      }

      &.hui-badge-arrow-top, &.hui-badge-arrow-bottom {
        &:before {
          transform: translateX(-50%) rotate(45deg);
          left: 50%;
        }

        &:after {
          transform: translateX(-50%);
          left: 50%;
          height: 7px;
          width: 16px;
        }
      }

      &.hui-badge-arrow-top {
        &:before {
          top: -3px;
        }

        &:after {
          top: 1px;
        }
      }

      &.hui-badge-arrow-bottom {
        &:before {
          bottom: -3px;
        }

        &:after {
          bottom: 1px;
        }
      }


      &.hui-badge-sm {
        &:before {
          width: 5px;
          height: 5px;
        }

        &.hui-badge-arrow-left, &.hui-badge-arrow-right {
          &:after {
            width: 4px;
            height: 5px;
          }
        }

        &.hui-badge-arrow-left {
          &:before {
            left: -2px;
          }

          &:after {
            left: 1px
          }
        }

        &.hui-badge-arrow-right {
          &:before {
            right: -2px;
          }

          &:after {
            right: 0;
          }
        }

        &.hui-badge-arrow-top, &.hui-badge-arrow-bottom {
          &:after {
            height: 4px;
            width: 5px;
          }
        }

        &.hui-badge-arrow-top {
          &:before {
            top: -2px;
          }

          &:after {
            top: 1px;
          }
        }

        &.hui-badge-arrow-bottom {
          &:before {
            bottom: -2px;
          }

          &:after {
            bottom: 1px;
          }
        }
      }
    }

    button.hui-badge-bg-#{$color} {
      &:hover {
        background-color: color.scale($value, $lightness: -2%);
      }
    }

    .hui-badge-text-#{$color} {
      color: $value;
    }

    button.hui-badge-text-#{$color} {
      &:hover {
        color: color.scale($value, $lightness: 5%);
      }
    }
  }
}
