$hui-radius-sm: 2px;
$hui-radius: 4px;
$hui-radius-lg: 7px;
$hui-radius-xl: 14px;

$hui-radius-rounded: 1000px;

.hui-radius-sm {
  border-radius: 2px;
}

.hui-radius {
  border-radius: 4px;
}

.hui-radius-lg {
  border-radius: 7px;
}

.hui-radius-xl {
  border-radius: 14px;
}

.hui-radius-rounded {
  border-radius: 1000px;
}



