// Replace bootstrap colors
$border-color: $hui-color-border;

// Layout variables
$hui-header: 80px;
$hui-header-padding: 0 24px;

// Nav
$hui-nav-width: 260px;

// Sidebar
$hui-sidebar-panel-width: 600px;
$hui-sidebar-panel-width-lg: 700px;
$hui-sidebar-panel-max-width: 950px;

$hui-card-padding: 30px;
