.hui-tabs {
  display: flex;
  box-shadow: inset 0 -1px 0 $hui-color-border;
  height: 60px;
}

.hui-tabs-item {
  display: flex;
  line-height: 60px;

  &.active {
    a {
      background-color: white;
      color: $hui-color-black;
      box-shadow: inset 0 -3px 0 $hui-color-green;
    }
  }

  a {
    padding: 0 20px;
    outline: none;
    font-weight: bold;
    font-size: 15px;
    text-decoration: none;
    color: $hui-color-default;
    margin-right: 1px;
  }

  &:hover {
    a {
      background-color: $hui-color-gray-light;
      box-shadow: inset 0 -1px 0 $hui-color-border;
    }

    &.active {
      a {
        background-color: white;
        color: $hui-color-black;
        box-shadow: inset 0 -3px 0 $hui-color-green;
      }
    }

  }
}


