.hui-alert {
}

.hui-alert-border {
  &.hui-alert-blue {
    border-color: rgba($hui-color-blue, .22) !important;
  }

  &.hui-alert-green {
    border-color: rgba($hui-color-green, .22) !important;
  }

  &.hui-alert-yellow {
    border-color: rgba($hui-color-yellow, .22) !important;
  }

  &.hui-alert-red {
    border-color: rgba($hui-color-red, .22) !important;
  }

  &.hui-alert-gray {
    border-color: rgba($hui-color-gray, .22) !important;
  }

  &.hui-alert-black {
    border-color: rgba($hui-color-black, .5) !important;
  }
}

.hui-alert-content {
  opacity: .86;
}
