@mixin hui-themes($themes: "blue", $color1: $hui-color-theme-blue, $color2: $hui-color-theme-blue-light, $color3: $hui-color-theme-blue-border, $color4: $hui-color-theme-blue ) {
  @each $theme, $value in $themes {
    .hui-theme-#{$theme} {
      // loading
      .hui-loading {
        border-top-color: $color1;
      }

      //signin & signup backgroud
      .hui-portal-copy-bg {
        background-color: $color2;

        &:before{
          background-color: $color4 !important;
        }
        &:after {
          border-right-color: #ffffff;
        }
      }

      .hui-portal-copy-laptop {
        background-image: linear-gradient(to bottom, transparent 0%, $color2 100%) !important;
      }

      .hui-portal-copy-laptop #portal-copy-image-logo{
        fill: $color1;
      }
      .hui-portal-copy-laptop #portal-copy-image-title{
        stroke: $color1;
      }


      //progress bar
      .MuiLinearProgress-bar {
        background-color: $color1 !important;
      }

      // avatar
      .hui-header-avatar {
        background-color: $color2 !important;
        border-color: $color3 !important;
      }

      // company nav
      .hui-dashboard-company-name {
        color: $hui-color-black !important;
      }

      // company nav
      .google-static-marker {
        path {
          fill: $color1;
        }
      }

      // menu
      .hui-nav-menu-item.active {
        background: $color2 !important;

        .hui-nav-menu-link {
          color: $hui-color-black !important;



          svg {
            path {
              fill: rgba($color1, .05);
            }

            path {
              stroke: $color1;
            }
          }
        }
      }

      // Cards
      .hui-theme-card {
        background: $color2 !important;
      }

      .hui-card-actions {
        border-color: $color3 !important;
      }

      // num
      .hui-panel-header-num {
        background-color: $color1;
      }

      .rgt-row-invoice-selected {
        background-color: $color2 !important;
      }


      //tabs


      .hui-tabs-item {
        display: flex;
        line-height: 60px;

        &.active {
          a {
            box-shadow: inset 0 -3px 0 $color1;
          }
        }

        &:hover {
          &.active {
            a {
              box-shadow: inset 0 -3px 0 $color1;
            }
          }
        }
      }
    }
  }
}
