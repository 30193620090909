.hui-pay-invoices-table {
  height: calc(100vh - 335px) !important;
}

.hui-payment-type {
  width: 100%;
  background-color: $hui-color-gray-light;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  padding: 10px;
  border-radius: 20px;

  @media all and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.hui-payment-type-single {
  grid-template-columns: 1fr;
}

.hui-pay-invoices-select {
  width: 340px;
  display: block;
}

.hui-pay-invoices-total {
  width: 130px;
  min-width: 130px;

  input {
    text-align: right;
  }
}

.hui-pay-invoices-reason {
  width: 160px;
  max-width: 160px;
  min-width: 160px;
}

.hui-payment-type-option {
  position: relative;
  overflow: hidden;
  background: #F1F1F3;
  border: 2px solid $hui-color-gray-light;
  text-decoration: none;
  color: $hui-color-gray;

  &:first-child {
    border-radius: 15px 0 0 15px;
  }

  &:last-child {
    border-radius: 0 15px 15px 0;
  }

  &:hover {
    border-color: $hui-color-black;
    background: #fff;
    color: $hui-color-black;
  }

  &.hui-payment-type-active {
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    color: $hui-color-black;
  }
}

.hui-payment-type-icon {
  overflow: hidden;
}

// select payment

.hui-select-payment-alert {
  max-width: 1000px;
}

.hui-select-payment {
  width: 100%;
  max-width: 1000px;
  background-color: $hui-color-gray-light;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  padding: 10px;
  border-radius: 20px;

  @media all and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  &.hui-select-payment-options-1 {
    grid-template-columns: 1fr;

    .hui-select-payment-option {
      border-radius: 15px !important;
    }
  }

  &.hui-select-payment-options-3 {
    grid-template-columns: 1fr 1fr 1fr;

    .hui-select-payment-option {
      border-radius: 15px !important;
    }
  }
}

.hui-select-payment-option {
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;

  &:first-child {
    border-radius: 15px 0 0 15px;
  }

  &:last-child {
    border-radius: 0 15px 15px 0;
  }

  &.hui-select-payment-type {
    background-color: white;
    border: 2px solid $hui-color-gray-light;

    &:hover {
      border-color: $hui-color-black;

      .hui-btn {
        background-color: $hui-color-green !important;
        color: white !important;
      }
    }

    .hui-btn {
      background-color: transparent;
    }
  }

  &.hui-select-payment-active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    border-color: white !important;

    .hui-btn {
      color: white !important;
      background: $hui-color-green !important;
    }
  }

  &.hui-select-payment-disabled {
    color: #8E909E;
    background-color: #F1F1F3;
    border-color: $hui-color-border !important;

    .hui-select-payment-card {
      filter: grayscale(1);
      opacity: .4;
    }

    .hui-btn {
      background: #8E909E !important;
      color: white !important;
    }

    &:hover {
      border-color: $hui-color-border !important;
      background-color: #F1F1F3 !important;

      .hui-btn {
        background: #8E909E !important;
        color: white !important;
      }
    }
  }

  &.hui-select-payment-minimum-alert {
    background-color: #F1F1F3;
    border-color: $hui-color-border;

    .hui-btn {
      background: #8E909E !important;
      color: white !important;
    }

    &:hover {
      border-color: $hui-color-border !important;
      background-color: #F1F1F3 !important;

      .hui-btn {
        background: #8E909E !important;
        color: white !important;
      }
    }
  }

  &.hui-select-payment-not-selected {
    color: #8E909E;
    background-color: white;
    transition: background-color .2s linear;

    .hui-title {
      color: $hui-color-black;;
    }

    .hui-btn {
      background: white !important;
      color: $hui-color-green !important;
    }

    &.hui-select-payment-CREDIT {
      .hui-btn {
        background: $hui-color-green !important;
        color: white !important;
      }
    }

    &:hover {
      background-color: white;
      border-color: $hui-color-black;

      .hui-btn {
        background: $hui-color-green;
        color: white !important;
      }
    }
  }
}


.hui-select-payment-icon {
  background-color: $hui-color-gray-light;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.hui-confirm-payment {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 470px 1fr;
  grid-template-rows: 1fr;
  gap: 0 30px;
  grid-auto-flow: row;
  border-radius: 20px;

  @media all and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.hui-payment-total {
  border-top: 1px solid $hui-color-border;
}

.hui-payment-total-total {
  @media (min-width: 768px) {
    border-left: 1px solid $hui-color-border;
  }
}


.hui-sidebar-loading {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
}

.hui-pay-confirm-invoices {
  position: relative;
  margin-bottom: 30px;

  .hui-pay-confirm-invoices-item {
    min-height: 45px;
    line-height: 15px;
    border-bottom: 1px solid $hui-color-border;


    &:last-child {
      border-bottom: 0 !important;
    }


    .hui-pay-confirm-credits {
      flex-basis: 100%;
    }

    .hui-pay-confirm-credit {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 12px;
        width: 8px;
        border-width: 0;
        border-bottom-width: 1.5px;
        border-radius: 0 0 0 5px;
        border-left-width: 1.5px;
        border-color: $hui-color-gray;
        border-style: solid;
        left: 3px;
        top: 0;
      }
    }
  }

  &.hui-pay-confirm-invoices-scroll {
    max-height: 360px;

    &:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background-image: linear-gradient(0deg, white 0%, rgba(255, 255, 255, .0) 100%) !important;
      background-repeat: repeat-x;
      background-position: center bottom;
    }

    &:after {
      position: absolute;
      content: '↓ SCROLL DOWN';
      background-color: $hui-color-gray-light;
      border: 1px solid $hui-color-border;
      padding: 0 12px;
      border-radius: 100px;
      color: $hui-color-gray;
      font-size: 11px;
      left: 50%;
      line-height: 24px;
      height: 25px;
      transform: translateX(-50%);
      bottom: -13px;
    }

    .hui-pay-confirm-invoices-list {
      max-height: 360px;
      padding-bottom: 30px;
      overflow: auto;
    }
  }
}

.hui-select-payment-CREDIT {
   .hui-btn {
    background-color: $hui-color-green !important;
    color: white !important;
  }
}
