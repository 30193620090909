
.hui-loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;

  * {
    user-select: none;
  }

  &.hui-loading-fixed{
    position: relative;
  }
}



.hui-loading-md {
  &.hui-loading-fixed{
    width: 80px;
    height: 80px;
  }

  .hui-loading {
    width: 80px;
    height: 80px;
    border: 8px solid $hui-color-border;
  }
}

.hui-loading-sm {

  &.hui-loading-fixed{
    width: 40px;
    height: 40px;
  }

  .hui-loading {
    width: 40px;
    height: 40px;
    border: 4px solid $hui-color-border;
  }
}


.hui-loading {
  border-top-color: $hui-color-default;
  border-radius: 50%;
  animation: hui-loading-spin 1s linear infinite;
}

@keyframes hui-loading-spin {
  to {
    transform: rotate(360deg);
  }
}
