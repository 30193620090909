@font-face {
  font-display: fallback;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../../../assets/fonts/Lato-Regular.eot');
  src: local('Lato Regular'),
  local('Lato-Regular'),
  url('../../../../assets/fonts/Lato-Regular.eot') format('embedded-opentype'),
  url('../../../../assets/fonts/Lato-Regular.woff2') format('woff2'),
  url('../../../../assets/fonts/Lato-Regular.woff') format('woff'),
  url('../../../../assets/fonts/Lato-Regular.ttf') format('truetype'),
  url('../../../../assets/fonts/Lato-Regular.svg') format('svg');
}

@font-face {
  font-display: fallback;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../../../assets/fonts/Lato-Bold.eot');
  src: local('Lato Bold'),
  local('Lato-Bold'),
  url('../../../../assets/fonts/Lato-Bold.eot') format('embedded-opentype'),
  url('../../../../assets/fonts/Lato-Bold.woff2') format('woff2'),
  url('../../../../assets/fonts/Lato-Bold.woff') format('woff'),
  url('../../../../assets/fonts/Lato-Bold.ttf') format('truetype'),
  url('../../../../assets/fonts/Lato-Bold.svg') format('svg');
}
