.hui-title {
  font-size: 28px;
  font-weight: 900;
  letter-spacing: -0.57px;
  line-height: 32px;

  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0;
  }

  &-lg {
    font-size: 32px;
    letter-spacing: -0.68px;
    line-height: 38px;
    font-weight: 900;

    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }
}
