
.hui-subtitle {
  font-size: 22px;
  letter-spacing: -0.06px;
  line-height: 28px;
  font-weight: 900;

  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
  }

  &-lg{
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.06px;
    font-weight: 900;

    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0;
    }
  }
}

