.hui-thanks-info {
  max-width: 1000px;
}

.hui-thanks-payment {
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-template-rows: 1fr;
  gap: 0 30px;
  grid-auto-flow: row;
  border-radius: 20px;

  @media all and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.hui-thanks-actions {
  width: 100%;
  position: sticky;
  top: 104px;
  height: 500px;
}

.hui-thanks-content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
