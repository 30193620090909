.hui-hero {
  font-size: 52px;
  letter-spacing: -1.38px;
  line-height: 56px;
  font-weight: 900;

  @media (max-width: 991px) {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0;
  }

  &-lg {
    font-size: 74px;
    letter-spacing: -1.68px;
    line-height: 70px;
    font-weight: 900;

    @media (max-width: 991px) {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -0.68px;
    }
  }
}

