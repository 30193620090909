.hui-main {
  position: relative;
  min-height: 100%;
}

@media (max-width: 991px) {
  .hui-main-form {
    max-width: 500px;
    margin: 0 auto;
  }
}
