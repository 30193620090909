.hui-payment-method-icon {
  border: 1px solid $border-color;
  border-radius: 4px;
  overflow: hidden;
  width: 24px;
  height: 17px;

  svg {
    width: 100%;
    height: 100%;
  }
}
