
.or-line {
  height: 14px;
  display: block;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  color: $hui-color-gray;
  z-index: 1;
  width: 280px;
  margin-left: auto !important;
  margin-right: auto !important;

  &:before, &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    background-color: #ffffff;
    width: 50px;
    height: 14px;
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
  }

  &:after {
    z-index: -2;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $hui-color-border;
  }
}

