.hui-btn {
	&.hui-btn-link {
		border: 0;
		background-color: transparent;
		text-decoration: underline;
		font-weight: normal;
    position: relative;

		&:hover, &:active {
			background: none;
			text-decoration: underline !important;
		}

    .hui-i {
      top: inherit;
      transform: none;
      vertical-align: initial;
    }
	}
}
