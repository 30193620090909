
.google-static-map {
  position: relative;
  height: 175px;
  overflow: hidden;
  pointer-events: none;
}

.google-static-marker{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  transform: translate(-50%, -50%);
}

.google-static-image{
  width: 100%;
  margin-top: -25px;
}
