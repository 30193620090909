.hui-panel {
  background: #FFFFFF;
  border-radius: 15px;
  overflow: hidden;

  &.hui-panel-shadow {
    box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
  }

  &.hui-panel-border {
    border: 1px solid $hui-color-border;
  }
}

.hui-panel-content {
  //padding: 20px;
}

.hui-panel-child {
  scroll-margin: $hui-header + 100;
  border-bottom: 1px solid $hui-color-border;

  &:last-child {
    border-bottom: 0;
  }
}

.hui-panel-actions {
  border: 0;
  border-style: solid;
  border-top-width: 1px;
  border-color: $hui-color-border;
  padding: 20px $hui-card-padding;
}

.hui-panel-header {
  min-height: 80px;
}


.hui-panel-header-num {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $hui-color-default;
  font-family: 'Helvetica Neue', sans-serif;
  color: white;
  font-size: 18px;
  font-weight: normal !important;
  line-height: 30px;
  text-align: center;
}


.revision-history {
  position: relative;

  &-item {
    position: relative;
    background: white;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      background-color: $hui-color-border;
      left: 20px;
      height: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      right: 0;
      background-color: $hui-color-border;
      left: 50px;
      height: 1px;
      bottom: 5px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &.flex-column-reverse {
    .revision-history-item {
      &:first-child {
        &:before {
          height: 10px;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &:not(.flex-column-reverse) {
    .revision-history-item {
      &:last-child {
        &:before {
          display: none !important;
        }

        .revision-history-inner {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  &.flex-column-reverse {
    .revision-history-item {

      &:after {
        display: block !important;
      }

      &:first-child {
        .revision-history-inner {
          padding-bottom: 0 !important;
        }


        &:after {
          display: none !important;
        }

      }
    }
  }

  &-inner {
    padding: 0 0 20px 50px;
    display: block;
    width: 100%;
  }

  &-icon {
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid $hui-color-border;
    border-radius: 100px;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 20px;
    transform: translateY(-50%);
    background-size: 90% auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.icon-small {
      position: absolute;
      top: 14px;
      left: -44px;
      width: 30px;
      height: 30px;
    }
  }
}

.recurrences-list {
  max-height: calc(100vh - 300px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.recurrence-payments {
  position: relative;

  &-item {
    position: relative;
    background: white;

    &:before {
      position: absolute;
      content: '';
      width: 1px;
      background-color: $hui-color-border;
      left: 20px;
      height: 100%;
    }

    &:after {
      position: absolute;
      content: '';
      right: 0;
      background-color: $hui-color-border;
      left: 50px;
      height: 1px;
      bottom: 10px;
    }

    &:first-child {
      &:after, &:before {
        display: none;
      }

      div {
        padding-bottom: 0 !important;
      }
    }
  }

  &.flex-column-reverse {
    .revision-history-item {
      &:first-child {
        &:before {
          height: 10px;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &:not(.flex-column-reverse) {
    .revision-history-item {
      &:last-child {
        &:before {
          display: none !important;
        }

        .revision-history-inner {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  &.flex-column-reverse {
    .revision-history-item {

      &:after {
        display: block !important;
      }

      &:first-child {
        .revision-history-inner {
          padding-bottom: 0 !important;
        }


        &:after {
          display: none !important;
        }

      }
    }
  }

  &-inner {
    padding: 0 0 20px 55px;
    display: block;
    width: 100%;
  }

  &-num {
    font-size: 15px;
    line-height: 15px;
    font-weight: bold;
    color: $hui-color-black-light;
  }

  &-total {
    font-size: 10px;
    line-height: 11px;
    text-transform: uppercase;
    color: $hui-color-default;
    letter-spacing: -0.5px;
  }

  &-icon {
    width: 45px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid $hui-color-border;
    border-radius: 100px;
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 22px;
    transform: translateY(-50%);
    background-size: 90% auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.approved {
      background-color: $hui-color-green;
      border-color: $hui-color-green;
      color: #fff;

      .recurrence-payments-num, .recurrence-payments-total {
        color: #fff;
      }
    }

    &.canceled {
      background-color: $hui-color-red-light;
      border-color: #f2e1df;
      color: $hui-color-red;

      .recurrence-payments-num, .recurrence-payments-total {
        color: $hui-color-red;
      }
    }
  }
}


.recurrence-payments-title {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 1px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background-color: $hui-color-border;
  }

  span {
    background-color: $hui-color-gray-light;
    border: 1px solid $hui-color-border;
    padding: 0 12px;
    border-radius: 100px;
    color: $hui-color-gray;
    font-size: 11px;
    line-height: 24px;
    height: 25px;
    margin: 0 auto;
    display: inline-block;
    z-index: 1;
    text-transform: uppercase;
  }
}
