@import "hui-general/google-static-map";
@import "hui-general/or-line";
@import "hui-general/portal-logo";
@import "hui-general/hui-filters";

.text-pre {
  white-space: pre;
}

.text-end input {
  text-align: right !important;
}


.no-events {
  pointer-events: none;
}


.hui-calendar {
  width: 100%;
  
  .rdrMonth {
    width: 100%;
  }
}

.hui-calendar-hide-passive {
  .rdrDayPassive  {
    display: none;
  }
}

.hui-date-range{
  &.rdrDefinedRangesWrapper{
    width: 100%;
    border: 0;
  }
  .rdrInputRanges{
    display: none;
  }
  .rdrStaticRange{
    &:last-child{
      border-bottom: 0;
    }
  }
}
