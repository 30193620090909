.hui-nav {
  width: $hui-nav-width;
  min-width: $hui-nav-width;
  max-width: $hui-nav-width;
  left: 0;
  bottom: 0;
  z-index: 99;
  top: 0;
  height: calc(100vh);

  @media (max-width: 767px) {
    display: none !important;
  }

  @media (max-width: 1320px) {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
  }

  @media (max-width: 767px) {
    &.hui-nav-open {
      display: block !important;
      position: fixed !important;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      border: 0 !important;
    }
  }
}
