@mixin toastr-types($types: "green", $color: $hui-color-green ) {
  @each $type, $value in $types {
    &.toast-#{$type} {
      box-shadow: 1px 3px 25px rgba(scale-color($color, $lightness: -60%), 0.08);
      color: scale-color($color, $lightness: -70%);
      background-color: scale-color($color, $lightness: 95%);
      border-color: rgba(scale-color($color, $lightness: 55%), 0.5);

      &:hover{
        box-shadow: 3px 5px 30px rgba(scale-color($color, $lightness: -50%), 0.12);
      }

      .toast-title {
        font-weight: bold;
        color: scale-color($color, $lightness: -20%);
      }

      &:before{
        @extend .hui-svg-toastr-#{$type} !optional;
      }

      .toast-close-button {
        background-color: $color;
        border-color: rgba(scale-color($color, $lightness: 55%), 0.5);

        &:hover{
          background-color: scale-color($color, $lightness: -10%);
        }
      }
    }
  }
}
