.hui-text {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.008em;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
  }

  &-lg {
    font-size: 18px;
    letter-spacing: -0.05px;
    line-height: 28px;
    font-weight: 400;

    @media (max-width: 991px) {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }

  &-sm {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  &-xs {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.3px;
  }
}
