.hui-portal-copy-bg {
  position: relative;

  &:after, &:before {
    content: '';
    position: absolute;
    bottom: 0;
  }

  &:before {
    height: 380px;
    left: 0;
    right: 0;
  }

  &:after {
    border-right: 400px solid transparent;
    border-bottom: 380px solid transparent;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    right: 0;
    z-index: 0;
    opacity: .05;
  }
}

.hui-portal-copy {
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 1200px) {
    margin-bottom: 150px;
  }


  @media (min-width: 1200px) {
    max-width: 500px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 300px;
  }
}


.hui-portal-features {
  margin: 0;
  padding: 0;
  list-style: none;

  &-item {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}


.hui-portal-copy-laptop {
  text-align: center;
}

.hui-portal-copy-payment {
  position: absolute;
  left: -50px;
  bottom: -50px;

  img {
    @media (max-width: 1299px) {
      width: 250px !important;
    }
  }

  @media (min-width: 1300px) {
    left: -110px;
    bottom: -140px;
  }
}

.hui-portal-copy-waiver {
  position: absolute;
  right: 0;
  bottom: -40px;

  @media (max-width: 1299px) {
    img {
      width: 200px !important;
    }
  }

  @media (min-width: 1300px) {
    right: -110px;
    bottom: -70px;
  }
}
