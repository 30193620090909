.hui-shadow {
  box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);

  &-sm {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
  }

  &-lg {
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.07);
  }

  &-xl {
    box-shadow: 10px 25px 70px rgba(0, 0, 0, 0.07);
  }
}
