.hui-btn {
  cursor: pointer;
  background-color: $hui-color-white;
  border-width: 1px;
  border-style: solid;
  border-color: rgba($hui-color-black, .15);
  border-radius: $hui-btn-radius;
  outline: none;
  height: $hui-btn-size;
  line-height: $hui-btn-line-height;
  font-weight: bold;
  font-size: $hui-btn-font-size;
  padding-top: 0;
  padding-left: $hui-btn-padding;
  padding-bottom: 0;
  padding-right: $hui-btn-padding;
  text-transform: inherit;
  transition: background-color .1s linear;
  min-width: initial;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
  user-select: none;

  &:hover, &:active {
    outline: 0;
    border-color: rgba($hui-color-black, 1);
  }

  @include button-props($size: $hui-btn);
  @include button-props($size: $hui-btn-sm);
  @include button-props($size: $hui-btn-lg);
  @include button-props($size: $hui-btn-xl);
  @include button-props($size: $hui-btn-link);
  @include button-props($size: $hui-btn-circle);

  @include button-color($name: "black", $color: $hui-color-black, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "blue", $color: $hui-color-blue, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "green", $color: $hui-color-green, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "red", $color: $hui-color-red, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "yellow", $color: $hui-color-yellow, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "gray", $color: $hui-color-gray, $contrast: 8%, $text: $hui-color-white);
  @include button-color($name: "default", $color: $hui-color-default, $contrast: 8%, $text: $hui-color-white);

  @include button-color-light($name: "black-light", $color: $hui-color-border-light, $contrast: 18%, $text: $hui-color-black-light);
  @include button-color-light($name: "blue-light", $color: $hui-color-blue-light, $contrast: 18%, $text: $hui-color-blue);
  @include button-color-light($name: "green-light", $color: $hui-color-green-light, $contrast: 18%, $text: $hui-color-green);
  @include button-color-light($name: "red-light", $color: $hui-color-red-light, $contrast: 18%, $text: $hui-color-red);
  @include button-color-light($name: "yellow-light", $color: $hui-color-yellow-light, $contrast: 18%, $text: $hui-color-yellow);
  @include button-color-light($name: "gray-light", $color: $hui-color-gray-light, $contrast: 18%, $text: $hui-color-gray);
  @include button-color-light($name: "default-light", $color: $hui-color-default-light, $contrast: 18%, $text: $hui-color-default);

  &.hui-btn-transparent {
    border: 0;
    background-color: transparent;
    padding: 0;
    height: initial;
    line-height: initial;
    border-radius: 0;
  }
}
